import React, { useRef, useEffect } from 'react'
import Logo from '../logo'
import styles from './style.module.scss'
import { navigate, Link } from 'gatsby'
import Input from '../input'
import Button from '../button'
import { useSignIn, useQueryParams } from '../hooks'
import Form from '../form/api-form'
import { get } from 'lodash-es'
import { ACCOUNT_TYPE_OWNER } from '../../constants'

export default () => {
    const formRef = useRef({})

    const params = useQueryParams()

    const { statusCode, submit, loading, response } = useSignIn({ formRef })

    useEffect(() => {
        const type = get(response, 'data.accountType')

        if (!type && statusCode === 200) return // wait until we get the account type

        if (statusCode === 200 && type === ACCOUNT_TYPE_OWNER) {
            if (params && params.redirect) {
                navigate(params.redirect)
            } else {
                navigate('/owner-dashboard')
            }
        } else if (statusCode === 200 && params && params.redirect) {
            navigate(params.redirect)
        } else if (statusCode === 200) {
            navigate('/')
        }
    }, [statusCode, response])

    return (
        <div className={styles.full}>
            <Link to="/">
                <Logo className={styles.logo} />
            </Link>
            <div className={styles.formWrapper}>
                <Form
                    statusCode={statusCode}
                    ref={formRef}
                    loading={loading}
                    response={response}
                    errorsClassName={styles.errors}
                    onSubmit={() => submit()}
                >
                    <h1 className={styles.title}>Sign in</h1>

                    <Input label={'Email'} name="email" type="email" required />

                    <Input
                        label="Password"
                        type="password"
                        name="password"
                        required
                    />
                    <div className={styles.footer}>
                        <Button className={styles.button}>Sign in</Button>
                        <div className={styles.footerLinks}>
                            <Link to="/forgot-password" className={styles.link}>
                                Forgot your password
                            </Link>
                            <span className={styles.verticalSep}>|</span>
                            <Link to="/sign-up" className={styles.link}>
                                Don't have an account
                            </Link>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}
